import Swal from 'sweetalert2';

export const toasterUtil = (title: string): void => {
  Swal.fire({
    title,
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    customClass: {},
  });
};
